.fullImg {
  width: 100%;
}

.afs {
  color: #ff9900;
}

.afsa {
  text-decoration: underline;
}

.listClass {
  list-style-type: circle;
}

.highlt {
  color: #1358E5;
}

.RedFont {
  color: #e60000;
}

.noBorder {
  border: none
}

.cF8F8F8 {
  color: #F8F8F8;
}

.cFFFFFF {
  color: #FFFFFF;
}

.c737373 {
  color: #737373;
}

.cFF5833 {
  color: #FF5833;
}

.c646063 {
  color: #646063;
}

.cRed {
  color: red;
}

.cBlue {
  color: blue;
}

.cGray {
  color: gray;
}

.cFont35 {
  font-size: 35px;
}

.cFont15 {
  font-size: 12px;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.logo {
  font-size: 18px;
  font-family: 'Courier New', monospace;
  text-align: center;
  font-weight: bold;
}

.font-weight-bold {
  font-weight: bold;
}

.cursiveGray {
  font-family: 'Brush Script MT', cursive;
  color: gray;
  text-align: center;
}

.cursiveGrayRight {
  font-family: 'Brush Script MT', cursive;
  color: gray;
  text-align: right;
}

.line2 {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}

.div-img-text {
  position: relative;
}

.footer-custom {
  background: linear-gradient(180deg, white, white, white, white, white, white, white, white, white, white, white, white, white);
}


.text-on-image {
  position: absolute;
  text-align: center;
  top: 10%;
  width: 100%;
}

.redBg {
  background-color: #ff8080;
}

.nav-link {
  color: black !important;
}

.center-align {
  text-align: center;
}

.width100 {
  width: 100%;
}